import { Flex, Box, Image, Heading, IconButton, Spacer, Link, Text, useDisclosure } from "@chakra-ui/react";
import { ReadonlyAccount } from "applesauce-accounts/accounts";
import { useNavigate, Link as RouterLink } from "react-router-dom";

import { nip19 } from "nostr-tools";
import { parseLNURLOrAddress } from "applesauce-core/helpers";
import { truncatedId } from "../../../helpers/nostr/event";
import { parseAddress } from "../../../services/dns-identity";

import { ChatIcon } from "@chakra-ui/icons";

import { EditIcon } from "../../../components/icons";
import UserAvatar from "../../../components/user/user-avatar";
import UserDnsIdentity from "../../../components/user/user-dns-identity";
import { useActiveAccount } from "applesauce-react/hooks";
import useUserProfile from "../../../hooks/use-user-profile";
import { UserProfileMenu } from "./user-profile-menu";
import { UserFollowButton } from "../../../components/user/user-follow-button";
import UserZapButton from "../components/user-zap-button";

import { useBreakpointValue } from "../../../providers/global/breakpoint-provider";
import UserName from "../../../components/user/user-name";
import UserAboutContent from "../../../components/user/user-about-content";
import { DNSIdentityWarning } from "../../../views/user/about";

import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExternalLinkIcon,
  KeyIcon,
  LightningIcon,
  VerifiedIcon,
} from "../../../components/icons";
import { CopyIconButton } from "../../../components/copy-icon-button";
import { QrIconButton } from "../components/share-qr-button";

export default function Header({
  pubkey,
  showRelaySelectionModal,
}: {
  pubkey: string;
  showRelaySelectionModal: () => void;
}) {
  const navigate = useNavigate();
  const metadata = useUserProfile(pubkey);
  const npub = nip19.npubEncode(pubkey);

  const parsedNip05 = metadata?.nip05 ? parseAddress(metadata.nip05) : undefined;
  const nip05URL = parsedNip05
    ? `https://${parsedNip05.domain}/.well-known/nostr.json?name=${parsedNip05.name}`
    : undefined;

  const account = useActiveAccount();
  const isSelf = pubkey === account?.pubkey;

  const showExtraButtons = useBreakpointValue({ base: false, sm: true });

  const expanded = useDisclosure();

  return (
    <Flex
      overflowY="auto"
      overflowX="hidden"
      direction="column"
      gap="2"
      pt={metadata?.banner ? 0 : "2"}
      pb="8"
      minH="35vh"
      w="full"
      flex={1}
    >
      <Box
        pt={!expanded.isOpen ? "20vh" : 0}
        px={!expanded.isOpen ? "2" : 0}
        pb={!expanded.isOpen ? "4" : 0}
        w="full"
        position="relative"
        backgroundImage={!expanded.isOpen ? metadata?.banner : ""}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      >
        {expanded.isOpen && <Image src={metadata?.banner} w="full" />}
        <Flex
          bottom="0"
          right="0"
          left="0"
          p="2"
          position="absolute"
          direction={["column", "row"]}
          bg="linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, var(--chakra-colors-chakra-body-bg) 100%)"
          gap="2"
          alignItems={["flex-start", "flex-end"]}
        >
          <UserAvatar square={false} pubkey={pubkey} size={["xl", "xl", "2xl"]} noProxy />
          <Box overflow="hidden">
            <Heading isTruncated>
              <UserName pubkey={pubkey} />
            </Heading>
            <UserDnsIdentity pubkey={pubkey} />
          </Box>

          <Flex gap="2" ml="auto">
            <UserZapButton pubkey={pubkey} size="sm" variant="link" />

            <IconButton
              as={RouterLink}
              size="sm"
              icon={<ChatIcon />}
              aria-label="Message"
              to={`/messages/${npub ?? pubkey}`}
            />
            <UserFollowButton pubkey={pubkey} size="sm" showLists />
            <UserProfileMenu pubkey={pubkey} aria-label="More Options" size="sm" />
          </Flex>
        </Flex>
        <IconButton
          icon={expanded.isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          aria-label="expand"
          onClick={expanded.onToggle}
          top="2"
          right="2"
          variant="solid"
          position="absolute"
        />
      </Box>
      <UserAboutContent pubkey={pubkey} />

      <Flex gap="2" px="2" direction="column">
        {npub && (
          <Flex gap="2">
            <KeyIcon boxSize="1.2em" />
            <Text>{truncatedId(npub, 10)}</Text>
            <CopyIconButton value={npub} title="Copy npub" aria-label="Copy npub" size="xs" />
            <QrIconButton pubkey={pubkey} title="Show QrCode" aria-label="Show QrCode" size="xs" />
          </Flex>
        )}

        {nip05URL && (
          <Box>
            <Flex gap="2">
              <VerifiedIcon boxSize="1.2em" />
              <Link href={nip05URL} isExternal>
                <UserDnsIdentity pubkey={pubkey} />
              </Link>
            </Flex>
            <DNSIdentityWarning pubkey={pubkey} />
          </Box>
        )}
        {metadata?.lud16 && (
          <Flex gap="2">
            <LightningIcon boxSize="1.2em" />
            <Link href={parseLNURLOrAddress(metadata.lud16)?.toString()} isExternal>
              {metadata.lud16}
            </Link>
          </Flex>
        )}

        {metadata?.website && (
          <Flex gap="2">
            <ExternalLinkIcon boxSize="1.2em" />
            <Link href={metadata.website} target="_blank" color="blue.500" isExternal>
              {metadata.website}
            </Link>
          </Flex>
        )}
      </Flex>
    </Flex>
  );

  return (
    <Flex direction="column" gap="2" px="2" pt="2">
      <Flex gap="2" alignItems="center">
        <UserAvatar pubkey={pubkey} size="sm" noProxy mr="2" />
        <Heading size="md" isTruncated>
          <UserName pubkey={pubkey} />
        </Heading>
        <UserDnsIdentity pubkey={pubkey} onlyIcon />
        <Spacer />
        {isSelf && !(account instanceof ReadonlyAccount) && (
          <IconButton
            icon={<EditIcon />}
            aria-label="Edit profile"
            title="Edit profile"
            size="sm"
            colorScheme="primary"
            onClick={() => navigate("/profile")}
          />
        )}
        {showExtraButtons && !isSelf && <UserFollowButton pubkey={pubkey} size="sm" />}
        <UserProfileMenu
          pubkey={pubkey}
          aria-label="More Options"
          size="sm"
          showRelaySelectionModal={showRelaySelectionModal}
        />
      </Flex>
    </Flex>
  );
}
