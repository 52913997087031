import RelayPool from "../classes/relay-pool";
import { offlineMode } from "./offline-mode";

const relayPoolService = new RelayPool();

offlineMode.subscribe((offline) => {
  if (offline) {
    for (const [_, relay] of relayPoolService.relays) relay.close();
  }
});

if (import.meta.env.DEV) {
  // @ts-expect-error debug
  window.relayPoolService = relayPoolService;
}

export default relayPoolService;
